export const commonJs = {
    // 获取当前时间戳
    currentTimestamp() {
        return (new Date()).getTime();
    },
    getCountDays() {
        var curDate = new Date();
        var curMonth = curDate.getMonth();
        curDate.setMonth(curMonth + 1);
        curDate.setDate(0);
        var monthDay = curDate.getDate();
        var dayArry = [];
        for (var k = 1; k <= monthDay; k++) {
            dayArry.push(k);
        }
        return dayArry
    },
    // 时间戳格式化年月日
    happenTimeFun(num, type) {
        if ((num + '').length < 13) {
            num = num * 1000;
        }
        let date = new Date(num);
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM; //月补0
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d; //天补0
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h; //小时补0
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m; //分钟补0
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s; //秒补0
        if (type == 'y') {
            return y
        } else if (type == 'ym') {
            return y + '年' + MM + '月'
        } else if (type == 'ymd') {
            return y + '-' + MM + '-' + d;
        } else if (type == 'hm') {
            return h + ':' + m;
        } else {
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        }
    },
}