import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import ajax from './utils'
import VueI18n from 'vue-i18n'

import VueAwesomeSwiper from 'vue-awesome-swiper'

import { Toast } from 'vant'; // 轻提示
Vue.use(Toast);

// import style
import 'swiper/swiper-bundle.css'
import './assets/css/style.css'
import './assets/css/common.scss'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    loading: require('./assets/init.png')
  })

Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        'zh':require('./components/language/zh'),
        'en':require('./components/language/en')
    }
}) 

Vue.prototype.$baseUrl = 'https://www.suzhousco.com'
Vue.prototype.$baseUrl1 = 'https://api.sucoch.com/cms'
Vue.prototype.$ajax = ajax;
//注册全局公用方法
import { commonJs } from "../common/index";
Vue.use(ElementUI);
Vue.prototype.__commonJs = commonJs;

Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

Vue.config.productionTip = false

new Vue({
    el: '#app',
    i18n,
    render: h => h(App),
    router
}).$mount('#app')